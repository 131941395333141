@import "primereact/resources/primereact.min.css";
@import "primeflex/primeflex.css";
@import "primeicons/primeicons.css";
@import "assets/css/app/layout.scss";
@import "../node_modules/prismjs/themes/prism-coy.css";
@import "assets/css/demo/flags.css";







.sp-toolbar {
    border: none;
    background-color: inherit;

}


.rect-logo {
    width: 200px;
    height: 200px;
}


.search-bank {

    .search-input {
        font-size: 20px;

    }
}



.chips-100 {
    width: 100%;

    .p-chips-multiple-container {
        width: 100%;
        min-height: 100px;
    }
}

.sp-required {
    color: red;
    // margin: 0px;
    // padding: 0px;
    line-height: 0px;
}



.p-datatable {
    // border: 1px solid rgba(0, 0, 0, 0.1);

}


.ss-data-table {
    padding-left: 1.5rem;
    padding-right: 1.5rem;

    padding-top: 50px;

    .ss-header {
        font-family: var(--para-font);
    }

    .p-card {
        overflow: auto;

        .p-card-body {
            padding: 0px;
        }

        .p-card-content {
            padding: 0px;
        }
    }

}

.app-title {
    color: white;

    // margin: 12px 0px 0px 10px;
    padding: 1rem;
    font-size: 24px;
    font-weight: 400;
    width: 200px;

    line-height: inherit;


}

.app-title-large {
    // margin-left: 44px;
}



.off-logo {
    // width: 30px;
    margin-right: 10px;
    height: 50px;
    top: 24px;
    //position: absolute;
}



.off-logo-large {
    height: 50px;
    top: 10px;
}

.off-logo-preview {
    // width: 30px;
    margin-left: 20px;
    height: 30px;
    // top: 24px;
    //   position: absolute;
}




.responsive-table {
    // overflow-y: auto;


    .member-name {
        min-width: 150px;
    }

    .money-to-be-paid {
        min-width: 150px;
    }
}


.print-page-hidden {
    display: none;
}


// @page {
//     size: A4;
//     margin: 20mm;
// }

@media print {

    .print-page-hidden {
        // margin: 20px;
        padding: 20px;
        display: block;
    }
}




.data-table-wr {

    width: 80%;
}

@media (max-width: 576px) {

    .policy-history-cards {
        width: 83vw;
    }

    .width-80p {
        // width: 53%;
        max-width: 82vw;
    }

    .width-85p {
        max-width: 85vw;
    }

    .policy-history-table {
        width: 300%;
    }

    .policy-table {
        width: 380%;
    }

    .add-policy-tbl {
        width: 380%;
    }


}

@media screen and (max-width: 769px) {


    .width-80p {
        // width: 53%;
        max-width: 82vw;
    }

    .width-85p {
        max-width: 85vw;
    }

    .policy-history-table {
        width: 300%;
    }


    .policy-table {
        width: 280%;
    }

    .add-policy-tbl {
        width: 280%;
    }
}


@media screen and (max-width: 960px) {
    .width-80p {
        // width: 53%;
        max-width: 82vw;
    }

    .width-85p {
        max-width: 85vw;
    }

    .policy-history-table {
        width: 200%;
    }


    .policy-table {
        width: 200%;
    }

    .add-policy-tbl {
        width: 200%;
    }
}




@media screen and (max-width: 1200px) {
    .width-80p {
        // width: 53%;
        max-width: 82vw;
    }

    .width-85p {
        max-width: 85vw;
    }

    .width-100p {
        max-width: 100vw;
    }


    .policy-history-table {
        width: 300%;
    }

    .policy-table {
        width: 300%;
    }

    .add-policy-tbl {
        width: 200%;
    }
}


.sp-alert-box {

    .sp-alert-img {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 50px;
    }

    .p-dialog-header {
        // padding: 8px;
        //display: none;
    }

    .sp-alert-head {
        text-align: center;
        font: normal normal bold 24px/32px Nunito Sans;
        letter-spacing: 0px;
        color: #1E1E1E;
        margin: 5px;
    }

    .sp-alert-msg {
        text-align: center;
        font: normal normal 500 17px/23px Nunito Sans;
        letter-spacing: 0px;
        color: #A2A2A2;
        margin: 5px;
    }
}
