.itro-card>div {
    min-height: calc(100vh - 76px);

    .intro-title-card {
        margin-top: 12%;
        padding-left: 10%;

    }
}

.fade-in {
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.fade-in.visible {
  opacity: 1;
}