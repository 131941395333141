.consultant-faqs {

    .grid {
        display: block;
        border: none !important;

    }

    .p-dataview.p-dataview-list .p-dataview-content>.p-grid>div {
        border: none;

    }
}
