.bg-color {
    background-color: #ffffff;
}

button.back-arrow {
    margin-right: 0.5rem;
    margin-left: 1.5rem;
    padding: 0.5rem;
    background-color: #6366F1;
    color: #ffffff;
    font-size: 1.5rem;
}



// button.addmore {
//     margin-right: 0.5rem;
//     padding: 0.5rem;
//     background-color: #6366F1;
//     color: #ffffff;
//     font-size: 2.5rem;
// }

.error-msg {
    color: 'var(--required)';
    font-size: '14px';
}
