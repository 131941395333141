// button.addmore {
//     margin-right: 0.5rem;
//     padding: 0.5rem;
//     background-color: #6366F1;
//     color: #ffffff;
//     font-size: 2.5rem;
// }


.dropdown-width {
    width: 200px;
}

button.close {
    margin-right: 0.5rem;
    padding: 0;
    color: #6366F1;
    font-size: 1.5rem;
    background-color: none;
    border: none;
    cursor: pointer;
}
