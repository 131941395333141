.resource-list-container {
    max-height: 300px; /* Adjust the maximum height as needed */
    overflow-y: auto;
}

.resource-list {
    list-style-type: none;
    padding: 0;
}

.resource-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;
}

.remove-button {
    background: none;
    border: none;
    cursor: pointer;
    color: #ff4d4f; /* Adjust the color as needed */
}
