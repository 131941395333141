.text {
    overflow: hidden;
    // white-space: nowrap;
    text-overflow: ellipsis;
    transition: max-height 0.3s ease;
}

.collapsed {
    max-height: 3em;
    /* Adjust based on line height */
}

.expanded {
    max-height: none;
    /* Allows full expansion */
}
