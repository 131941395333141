/* Styles for plan names container */
.plan-names-container {
    background-color: #FFFFFF;
    /* White background color */
    padding: 20px;
    width: 100%;
    /* Fill the entire width of the page */
    height: 100vh;
    /* Fill the entire height of the viewport */
    box-sizing: border-box;
    /* Include padding and border in the element's total width and height */
}


/* Styles for plan names list */
.plan-names-list {
    list-style-type: none;
    padding: 0;
}

/* Styles for plan name item */
.plan-name-item {
    margin-bottom: 10px;
}

/* Styles for details button */
.details-button {
    background-color: #007bff;
    color: #fff;
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

/* Styles for plan details container */
.plan-details-container {
    background-color: #FFFFFF;
    padding: 20px;
    width: 100%;
    /* Fill the entire width of the page */

    /* Fill the entire height of the viewport */
    box-sizing: border-box;


    .learning-plan {

        .grid {
            display: block;
            border: none !important;

        }

        .p-dataview.p-dataview-list .p-dataview-content>.p-grid>div {
            border: none;

        }
    }

}

/* Styles for plan details list */
.plan-details-list {
    list-style-type: none;
    padding: 0;
}

/* Styles for plan detail item */
.plan-detail-item {
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    width: 500px;
    align-items: center;
}

/* Styles for resources button */
.resources-button {
    background-color: #28a745;
    color: #fff;
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

/* Styles for go back button */
.go-back-button {
    background-color: #dc3545;
    color: #fff;
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

/* Styles for resources list */
.resources-list {
    padding: 10px;
}

/* Styles for resource item */
.resource-item {
    margin-bottom: 10px;
}

/* Styles for resource title */
.resource-title {
    font-weight: bold;
}

/* Styles for resource type */
.resource-type {
    font-style: italic;
}

/* Styles for resource link */
.resource-link {
    color: #007bff;
    text-decoration: none;
}

.button-container {
    margin-left: auto;
}

.section-title {
    font-size: 24px;
    font-weight: bold;
    color: #333;
    /* Change the color to your desired value */
    margin: 20px 0;
    /* Adjust the margin as needed */
}
