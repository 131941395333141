.tooltip-container {
    position: relative;
    display: inline-block;
}

.tooltip {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #fff;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: none;
}

.tooltip-container:hover .tooltip {
    display: block;
}
